import moment from 'moment/moment';
import _mock from './_mock';

export const _tissList = [...Array(10)].map((_, idx) => ({
  id: _mock.id(idx),
  patient: _mock.name.fullName(idx),
  healthInsurance: _mock.name.lastName(idx),
  consultDate: moment().format('DD/MM/YY'),
  tiss: '3.05.00',
  fatureDate: moment().format('DD/MM/YY'),
}));

export const _lotsList = [...Array(10)].map((_, idx) => ({
  id: _mock.id(idx),
  number: _mock.number.age(idx),
  healthInsurance: _mock.name.lastName(idx),
  date: moment().format('DD/MM/YY'),
  tiss: '3.05.00',
}));

export const _tissListComplete = [...Array(10)].map((_, idx) => ({
  id: _mock.id(idx),
  patientId: 'LABEL 1',
  ansRegister: 'LABEL 1',
  guideNumber: _mock.number.age(idx),
  guideNumberProvider: _mock.number.age(idx),
  patientCardNumber: '0000000',
  patientCardValidDate: moment().format('YYYY-MM-DD'),
  patientNewborn: _mock.boolean(idx),
  patientName: _mock.name.fullName(idx),
  patientCnsNumber: _mock.number.price(idx),
  providerCodeType: idx % 2 === 0 ? 'cpf' : idx % 3 === 0 ? 'cnpj' : 'code',
  providerCode: _mock.number.age(idx),
  providerName: _mock.name.firstName(idx),
  providerCnesCode: _mock.number.age(idx),
  providerExecutorName: _mock.name.fullName(idx),
  providerConcil: 'CRAS',
  providerNumber: _mock.number.age(idx),
  providerState: 'RS',
  providerCbos: 'LABEL 1',
  consultIndication: '2',
  consultDate: moment().format('YYYY-MM-DD'),
  consultType: '1',
  consultTableCode: '18',
  consultProcedureCode: _mock.number.age(idx),
  consultProcedurePrice: _mock.number.price(idx),
  consultObservations: _mock.text.description(idx),
}));

export const _lotsListComplete = [...Array(10)].map((_, idx) => ({
  id: _mock.id(idx),
  healthInsurance: '2',
  number: _mock.number.age(idx),
  providerName: _mock.name.fullName(idx),
  providerCode: _mock.number.age(idx),
  initialDate: moment().subtract(1, 'M').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  guides: [...Array(3)].map((_, idx) => _mock.id(idx)),
}));

export const _guidesSearch = [...Array(10)].map((_, idx) => ({
  id: _mock.id(idx),
  patientName: _mock.name.fullName(idx),
  date: moment().subtract(1, 'M').add(idx, 'day').format('DD/MM/YYYY'),
}));
