import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  professionalProfile: null,
  professionalDates: null,
  isPatientValid: null,
  isValidCupom: null,
  schedule: null,
};

const professionalProfileSlice = createSlice({
  name: 'professionalProfileSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      console.error('has error: ', action.payload);
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfessionalSuccess(state, action) {
      state.isLoading = false;
      state.professionalProfile = action.payload;
    },
    getProfessionalProceduresSuccess(state, action) {
      state.isLoading = false;
      state.professionalProcedures = action.payload;
    },
    getProfessionalDatesSuccess(state, action) {
      state.isLoading = false;
      state.professionalDates = action.payload;
    },
    getPatientSuccess(state, action) {
      state.isLoading = false;
      state.isPatientValid = action.payload;
    },
    getCupomSuccess(state, action) {
      state.isLoading = false;
      state.isValidCupom = action.payload;
    },
    saveScheduleSuccess(state, action) {
      state.isLoading = false;
      state.schedule = action.payload;
    },
    savePatientSuccess(state, action) {
      state.isLoading = false;
      state.patientProfile = action.payload;
    },
  },
});

export default professionalProfileSlice;

export const { selectEvent } = professionalProfileSlice.actions;

export const getProfessionalProfile = async professionalId => {
  try {
    dispatch(professionalProfileSlice.actions.startLoading());
    const response = await axios.get(`/public/professional/${professionalId}`);
    dispatch(
      professionalProfileSlice.actions.getProfessionalSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(professionalProfileSlice.actions.hasError(error));
  }
};

export const getProfessionalDates = async (procedureId, startDate, endDate) => {
  try {
    dispatch(professionalProfileSlice.actions.startLoading());
    const response = await axios.get(
      `schedule/procedureId/${procedureId}/startDate/${startDate}/finalDate/${endDate}/perfilPage`
    );
    dispatch(
      professionalProfileSlice.actions.getProfessionalDatesSuccess(
        response.data
      )
    );
    return response.data;
  } catch (error) {
    dispatch(professionalProfileSlice.actions.hasError(error));
  }
};

export const getPatient = async (patientDoc, clinicId) => {
  try {
    dispatch(professionalProfileSlice.actions.startLoading());
    const response = await axios.get(
      `public/professional/patient/${patientDoc}/clinic/${clinicId}`
    );
    dispatch(professionalProfileSlice.actions.getPatientSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(professionalProfileSlice.actions.hasError(error));
    return { error: 'Não possível buscar os dados do paciente!' };
  }
};

export const getCupom = async cupom => {
  try {
    dispatch(professionalProfileSlice.actions.startLoading());
    const response = await axios.get(`public/validate-cupom/${cupom}`);
    dispatch(professionalProfileSlice.actions.getCupomSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(professionalProfileSlice.actions.hasError(error));
  }
};

export const saveScheduleWith = async data => {
  try {
    dispatch(professionalProfileSlice.actions.startLoading());
    const response = await axios.post(`public/scheduling/with-patient`, data);
    dispatch(
      professionalProfileSlice.actions.saveScheduleSuccess(response.data.user)
    );
    return response.data;
  } catch (error) {
    dispatch(professionalProfileSlice.actions.hasError(error));
    return {
      error:
        error?.message
          ? error.message
          : "Não foi possível criar o agendamento!"
    };
  }
};

export const savePatient = async data => {
  try {
    dispatch(professionalProfileSlice.actions.startLoading());
    const response = await axios.post(`public/patient`, data);
    dispatch(
      professionalProfileSlice.actions.savePatientSuccess(response.data)
    );
    return response.data;
  } catch (error) {
    dispatch(professionalProfileSlice.actions.hasError(error));
    return { error: 'Não foi possível cadastrar o paciente!' };
  }
};
