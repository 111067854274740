import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  user: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    userSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
  },
});

export default slice.reducer;

export const { selectEvent } = slice.actions;

export function getUser() {
  const userJson = window.localStorage.getItem('@reufyUser');
  const user = JSON.parse(userJson);
  dispatch(slice.actions.userSuccess(user));
}
