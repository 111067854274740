import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';
import {
  _guidesSearch,
  _lotsList,
  _lotsListComplete,
  _tissList,
  _tissListComplete,
} from '../../../_mock/_tiss';
import { getCalendarOfProfessional } from '../../../redux/slices/schedule/schedule';

const initialState = {
  isLoading: false,
  isLoadingFinanceResume: false,
  isLoadingCashFlow: false,
  isLoadingTransactions: false,
  isLoadingTransactionToEdit: false,
  error: null,
  financeResume: null,
  cashFlow: null,
  transactions: null,
  transactionToEdit: null,
};

const FinanceSlice = createSlice({
  name: 'financeSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingFinanceResume(state) {
      state.isLoadingFinanceResume = true;
    },

    startLoadingCashFlow(state) {
      state.isLoadingCashFlow = true;
    },

    startLoadingTransactions(state) {
      state.isLoadingTransactions = true;
    },

    startLoadingTransactionToEdit(state) {
      state.isLoadingTransactionToEdit = true;
    },

    stopLoadingTransactionToEdit(state) {
      state.isLoadingTransactionToEdit = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFinanceResumeSuccess(state, action) {
      state.financeResume = action.payload;
      state.isLoadingFinanceResume = false;
    },

    getCashFlowSuccess(state, action) {
      state.cashFlow = action.payload;
      state.isLoadingCashFlow = false;
    },

    getTransactionsSuccess(state, action) {
      state.transactions = action.payload;
      state.isLoadingTransactions = false;
    },

    getTransactionToEditSuccess(state, action) {
      state.transactionToEdit = action.payload;
      state.isLoadingTransactionToEdit = false;
    },

    resetTransactionToEdit(state) {
      state.transactionToEdit = null;
    },
  },
});

export default FinanceSlice;

export const { resetTransactionToEdit } = FinanceSlice.actions;

export function getFinanceResume(filter, clinic) {
  return async () => {
    dispatch(FinanceSlice.actions.startLoadingFinanceResume());
    try {
      const response = await axios.get(
        `finance/finance-resume/${clinic}?filter=${filter}`
      );
      dispatch(FinanceSlice.actions.getFinanceResumeSuccess(response.data));
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
    }
  };
}

export function getCashFlow(bankId, financialCategoryId, clinicId) {
  return async () => {
    dispatch(FinanceSlice.actions.startLoadingCashFlow());
    try {
      const response = await axios.get(
        `finance/cash-flow/${clinicId}/${bankId}/${financialCategoryId}`
      );
      dispatch(FinanceSlice.actions.getCashFlowSuccess(response.data));
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
    }
  };
}

export function getTransactions(date, professional, clinic) {
  return async () => {
    dispatch(FinanceSlice.actions.startLoadingTransactions());
    try {

      const url = professional === null
        ? `finance/transaction/${clinic}?dateFilter=${date}`
        : `finance/transaction/${clinic}?dateFilter=${date}&filterProfessional=${professional}`;

      const response = await axios.get(url);
      dispatch(FinanceSlice.actions.getTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
    }
  };
}

export function getTransactionToEdit(clinicId, transactionId) {
  return async () => {
    dispatch(FinanceSlice.actions.startLoadingTransactionToEdit());
    try {
      const response = await axios.get(
        `finance/transaction/${clinicId}/${transactionId}`
      );
      dispatch(FinanceSlice.actions.getTransactionToEditSuccess(response.data));
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
    }
  };
}

export function deleteTransaction(clinicId, transactionId) {
  return async () => {
    dispatch(FinanceSlice.actions.startLoadingTransactionToEdit());
    try {
      const response = await axios.delete(
        `finance/transaction/${transactionId}`
      );
      dispatch(getTransactions(undefined, null, clinicId));
      dispatch(FinanceSlice.actions.stopLoadingTransactionToEdit());
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
    }
  };
}

export function createTransaction(clinicId, data) {
  return async () => {
    data.clinicId = clinicId;
    dispatch(FinanceSlice.actions.startLoadingTransactionToEdit());
    try {
      const response = await axios.post(
        `finance/transaction/${clinicId}`,
        data
      );
      dispatch(FinanceSlice.actions.stopLoadingTransactionToEdit());
      return response.data;
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
      return { error: "Não foi possível criar a transação!" };
    }
  };
}

export function updateTransaction(
  clinicId,
  data,
  transactionToEdit,
  scheduleDetails
) {
  return async () => {
    dispatch(FinanceSlice.actions.startLoadingTransactionToEdit());

    try {
      data.clinicId = clinicId;
      const response = await axios.put(
        `schedule/${scheduleDetails.scheduleId}`,
        { schedule: data, transactionDetails: transactionToEdit }
      );

      dispatch(getCalendarOfProfessional(clinicId, scheduleDetails.professional.professionalId));
      dispatch(getTransactions(undefined, null, scheduleDetails.clinicId));
      dispatch(FinanceSlice.actions.stopLoadingTransactionToEdit());
    } catch (error) {
      dispatch(FinanceSlice.actions.hasError(error));
    }
  };
}

export async function updateTransactionAndSchedule(transactionId, data) {
  try {
    const response = await axios.put(
      `finance/transaction/${transactionId}`, data
    );
    return response.data;
  } catch (error) {
    return { error };
  }
}
