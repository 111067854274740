import { Outlet, useLocation } from 'react-router-dom';
import Header from '@components/Header';
import { styled } from '@mui/material/styles';
import NavbarVertical from '../components/Navbar';
import { HEADER } from '../config';

const MainStyle = styled('main', {
  shouldForwardProp: prop => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT - 30,
  // paddingBottom: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 16,
    // paddingRight: 16,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function PacienteLayout() {
  const { pathname } = useLocation();

  return (
    <>
      {(pathname.includes('admin/clinica') ||
        pathname.includes('admin/profissional') ||
        pathname.includes('admin/financas') ||
        pathname.includes('admin/contratos') ||
        pathname.includes('admin/carga-horaria') ||
        pathname.includes('admin/salas') ||
        pathname.includes('admin/relatorios') ||
        pathname.includes('admin/tiss') ||
        pathname.includes('admin/pacientes/') ||
        pathname.includes('admin/agenda')) && <NavbarVertical />}
      <Header />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </>
  );
}
