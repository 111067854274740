export const date = [
  '28/01/2023',
  '30/01/2023',
  '08/01/2023',
  '12/01/2023',
  '12/01/2023',
  '19/01/2023',
  '15/01/2023',
  '10/01/2023',
  '25/01/2023',
  '12/01/2023',
  '19/01/2023',
  '11/01/2023',
  '08/01/2023',
  '06/01/2023',
  '29/01/2023',
  '13/01/2023',
  '16/01/2023',
  '02/01/2023',
  '19/01/2023',
  '13/01/2023',
  '22/01/2023',
  '30/01/2023',
  '05/01/2023',
  '27/01/2023',
  '18/01/2023',
  '01/01/2023',
  '07/01/2023',
  '10/01/2023',
  '06/01/2023',
  '02/01/2023',
  '29/01/2023',
  '17/01/2023',
  '06/01/2023',
  '19/01/2023',
  '19/01/2023',
  '22/01/2023',
  '22/01/2023',
  '09/01/2023',
  '05/01/2023',
  '13/01/2023',
  '29/01/2023',
  '29/01/2023',
  '25/01/2023',
  '10/01/2023',
  '10/01/2023',
  '16/01/2023',
  '22/01/2023',
  '08/01/2023',
  '01/01/2023',
  '18/01/2023',
  '30/01/2023',
  '06/01/2023',
  '14/01/2023',
  '01/01/2023',
  '09/01/2023',
  '06/01/2023',
  '22/01/2023',
  '24/01/2023',
  '24/01/2023',
  '18/01/2023',
  '10/01/2023',
  '12/01/2023',
  '09/01/2023',
  '17/01/2023',
  '05/01/2023',
  '17/01/2023',
  '30/01/2023',
  '14/01/2023',
  '22/01/2023',
  '28/01/2023',
  '13/01/2023',
  '11/01/2023',
  '30/01/2023',
  '04/01/2023',
  '04/01/2023',
  '19/01/2023',
  '05/01/2023',
  '20/01/2023',
  '22/01/2023',
  '14/01/2023',
  '14/01/2023',
  '25/01/2023',
  '13/01/2023',
  '12/01/2023',
  '19/01/2023',
  '20/01/2023',
  '13/01/2023',
  '04/01/2023',
  '14/01/2023',
  '15/01/2023',
  '20/01/2023',
  '04/01/2023',
  '07/01/2023',
  '19/01/2023',
  '02/01/2023',
  '25/01/2023',
  '10/01/2023',
  '22/01/2023',
  '02/01/2023',
  '23/01/2023',
];
