import moment from 'moment';
import _mock from './_mock';
import { _scheduleStatus } from './_scheduleStatus';

export const _schedules = [
  {
    id: '0',
    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30',
    title: 'Ana Pivatto Dias',
    patientName: 'Ana Pivatto Dias',
    patientId: '0',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    status: _scheduleStatus[0].name,
  },
  {
    id: '1',
    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30',
    title: 'Eduardo Costa',
    patientName: 'Eduardo Costa',
    patientId: '1',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    status: _scheduleStatus[1].name,
  },
  {
    id: '2',
    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30',
    title: 'Pamela Cunha',
    patientName: 'Pamela Cunha',
    patientId: '2',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    status: _scheduleStatus[2].name,
  },
  {
    id: '3',
    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30',
    title: 'Jorge Guido',
    patientName: 'Jorge Guido',
    patientId: '3',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    status: _scheduleStatus[3].name,
  },
  {
    id: '4',
    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30',
    title: 'João Gobbato',
    patientName: 'João Gobbato',
    patientId: '4',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    status: _scheduleStatus[4].name,
  },
];

export const _schedulesProfile = [
  {
    id: 1,
    hasMeet: true,
    meetLink: 'https://meet.google.com',
    allDay: false,
    status: _scheduleStatus[0].name,
    title: 'Ana Pivatto Dias',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    description: 'Ana Pivatto Dias',
    professionalId: 0,
    professionalName: 'Dra. Helena Oliveira',
    customer: {
      id: '642436815137bf4f45ab1aa2',
      name: 'Ana Pivatto Dias',
      phone: '(51) 99952-6987',
      email: 'anaPivatto@gmail.com',
    },

    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30', // pode ser excluído e usado apenas o start. se excluído, usar uma função de formatação de data na api ou no front
    serviceType: 'local',
    healthInsuranceId: 'Bradesco',
    healthInsuranceName: 'Bradesco',
    healthInsurancePlanId: 'Top',
    healthInsurancePlan: 'Top',
    isRecurrence: false,
    recurrence: 'dontRepeat',
    recurrenceType: 'until',
    recurrenceExpireDate: 'string',
    discountPrice: '0',
    payed: true,
    paymentPrice: '300,00',
    isParceled: false,
    incoming: '0',
    parcelsNumber: 0,
    parcelPrice: '0',
    account: '1',
    paymentMethod: 'credit',
    expireDate: '23/04/2023',
    paymentDate: '22/04/2023',
    alreadyPayed: true,
    procedures: [
      {
        id: '1',
        name: 'Consulta Gastro',
        quantity: 1,
        price: 210,
        paymentStatus: false,
      },
      {
        id: '2',
        name: 'Procedimento tal',
        quantity: 1,
        price: 50,
        paymentStatus: true,
      },
      {
        id: '3',
        name: 'Dentista',
        quantity: 1,
        price: 100,
        paymentStatus: true,
      },
    ],
  },
  {
    id: 2,
    hasMeet: true,
    meetLink: 'https://meet.google.com',
    allDay: false,
    status: _scheduleStatus[1].name,
    title: 'Eduardo Costa',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    description: 'Eduardo Costa',
    professionalId: 0,
    professionalName: 'Dra. Helena Oliveira',
    customer: {
      id: 1,
      name: 'Eduardo Costa',
      phone: '(51) 99952-6987',
      email: 'eduardocosta@gmail.com',
    },

    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30', // pode ser excluído e usado apenas o start. se excluído, usar uma função de formatação de data na api ou no front
    serviceType: 'local',
    healthInsuranceName: 'Bradesco',
    healthInsurancePlan: 'Top',
    isRecurrence: false,
    recurrence: 'dontRepeat',
    recurrenceType: 'until',
    recurrenceExpireDate: 'string',
    discountPrice: '0',
    payed: true,
    paymentPrice: '300,00',
    isParceled: false,
    incoming: '0',
    parcelsNumber: 0,
    parcelPrice: '0',
    account: '1',
    paymentMethod: 'credit',
    expireDate: '23/04/2023',
    paymentDate: '22/04/2023',
    alreadyPayed: false,
    procedures: [
      {
        id: '1',
        name: 'Consulta Gastro',
        quantity: 1,
        price: 320,
        paymentStatus: false,
      },
      {
        id: '2',
        name: 'Procedimento tal',
        quantity: 1,
        price: 50,
        paymentStatus: true,
      },
      {
        id: '3',
        name: 'Dentista',
        quantity: 1,
        price: 100,
        paymentStatus: true,
      },
    ],
  },
  {
    id: 3,
    hasMeet: true,
    meetLink: 'https://meet.google.com',
    allDay: false,
    status: _scheduleStatus[2].name,
    title: 'Pamela Cunha',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    description: 'Pamela Cunha',
    professionalId: 0,
    professionalName: 'Dra. Helena Oliveira',
    customer: {
      id: 1,
      name: 'Pamela Cunha',
      phone: '(51) 99952-6987',
      email: 'pamelacunha@gmail.com',
    },

    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30', // pode ser excluído e usado apenas o start. se excluído, usar uma função de formatação de data na api ou no front
    serviceType: 'local',
    healthInsuranceName: 'Bradesco',
    healthInsurancePlan: 'Top',
    isRecurrence: false,
    recurrence: 'dontRepeat',
    recurrenceType: 'until',
    recurrenceExpireDate: 'string',
    discountPrice: '0',
    payed: true,
    paymentPrice: '300,00',
    isParceled: false,
    incoming: '0',
    parcelsNumber: 0,
    parcelPrice: '0',
    account: '1',
    paymentMethod: 'credit',
    expireDate: '23/04/2023',
    paymentDate: '22/04/2023',
    alreadyPayed: false,
    procedures: [
      {
        id: '1',
        name: 'Consulta Gastro',
        quantity: 1,
        price: 390,
        paymentStatus: false,
      },
      {
        id: '2',
        name: 'Procedimento tal',
        quantity: 1,
        price: 50,
        paymentStatus: true,
      },
      {
        id: '3',
        name: 'Dentista',
        quantity: 1,
        price: 100,
        paymentStatus: true,
      },
    ],
  },
  {
    id: 4,
    hasMeet: true,
    meetLink: 'https://meet.google.com',
    allDay: false,
    status: _scheduleStatus[3].name,
    title: 'Jorge Guido',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    description: 'Jorge Guido',
    professionalId: 0,
    professionalName: 'Dra. Helena Oliveira',
    customer: {
      id: 1,
      name: 'Jorge Guido',
      phone: '(51) 99952-6987',
      email: 'jorgeguido@gmail.com',
    },

    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30', // pode ser excluído e usado apenas o start. se excluído, usar uma função de formatação de data na api ou no front
    serviceType: 'local',
    healthInsuranceName: 'Bradesco',
    healthInsurancePlan: 'Top',
    isRecurrence: false,
    recurrence: 'dontRepeat',
    recurrenceType: 'until',
    recurrenceExpireDate: 'string',
    discountPrice: '0',
    payed: true,
    paymentPrice: '300,00',
    isParceled: false,
    incoming: '0',
    parcelsNumber: 0,
    parcelPrice: '0',
    account: '1',
    paymentMethod: 'credit',
    expireDate: '23/04/2023',
    paymentDate: '22/04/2023',
    alreadyPayed: false,
    procedures: [
      {
        id: '1',
        name: 'Consulta Gastro',
        quantity: 1,
        price: 320,
        paymentStatus: false,
      },
      {
        id: '2',
        name: 'Procedimento tal',
        quantity: 1,
        price: 50,
        paymentStatus: true,
      },
      {
        id: '3',
        name: 'Dentista',
        quantity: 1,
        price: 100,
        paymentStatus: true,
      },
    ],
  },
  {
    id: 5,
    hasMeet: true,
    meetLink: 'https://meet.google.com',
    allDay: false,
    status: _scheduleStatus[4].name,
    title: 'João Gobbato',
    start: new Date().setHours(9),
    end: new Date().setHours(10),
    description: 'João Gobbato',
    professionalId: 0,
    professionalName: 'Dra. Helena Oliveira',
    customer: {
      id: 1,
      name: 'João Gobbato',
      phone: '(51) 99952-6987',
      email: 'joaogobbato@gmail.com',
    },

    scheduledDate: 'Quarta-feira, 07 de Dezembro - 13:00 às 13:30', // pode ser excluído e usado apenas o start. se excluído, usar uma função de formatação de data na api ou no front
    serviceType: 'local',
    healthInsuranceName: 'Bradesco',
    healthInsurancePlan: 'Top',
    isRecurrence: false,
    recurrence: 'dontRepeat',
    recurrenceType: 'until',
    recurrenceExpireDate: 'string',
    discountPrice: '0',
    payed: true,
    paymentPrice: '300,00',
    isParceled: false,
    incoming: '0',
    parcelsNumber: 0,
    parcelPrice: '0',
    account: '1',
    paymentMethod: 'credit',
    expireDate: '23/04/2023',
    paymentDate: '22/04/2023',
    alreadyPayed: false,
    procedures: [
      {
        id: '1',
        name: 'Consulta Gastro',
        quantity: 1,
        price: 320,
        paymentStatus: false,
      },
      {
        id: '2',
        name: 'Procedimento tal',
        quantity: 1,
        price: 50,
        paymentStatus: true,
      },
      {
        id: '3',
        name: 'Dentista',
        quantity: 1,
        price: 100,
        paymentStatus: true,
      },
    ],
  },
];

export const _nextPatients = [...Array(13)].map((_, idx) => ({
  scheduleId: _mock.id(idx),
  patientName: _mock.name.fullName(idx),
  patientId: _mock.id(idx),
  startHour: moment().format('HH:mm'),
  endHour: moment().format('HH:mm'),
  status:
    idx % 2 === 0 ? 'scheduled' : idx % 3 === 0 ? 'confirmed' : 'awaiting',
}));
