import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../redux/store';
import axios from '../../../utils/axios';
import {
  _guidesSearch,
  _lotsList,
  _lotsListComplete,
  _tissList,
  _tissListComplete,
} from '../../../_mock/_tiss';

const initialState = {
  isLoading: false,
  isLoadingConsultGuides: false,
  isLoadingConsultGuideToEdit: false,
  isLoadingLots: false,
  isLoadingLotToEdit: false,
  isLoadingSearchGuides: false,
  isLoadingOptions: false,
  error: null,
  consultGuidesList: [],
  lotsList: [],
  searchGuides: [],
  consultGuideToEdit: null,
  lotToEdit: null,
  patientOptions: [],
  tuss36Options: [],
  tuss52Options: [],
  tuss22Options: [],
  healthOptions: [],
  procedureCodesOptions: [],
  cbosOptions: [],
};

const TissSlice = createSlice({
  name: 'tissSlice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    startLoadingConsultGuides(state) {
      state.isLoadingConsultGuides = true;
    },

    startLoadingConsultGuideToEdit(state) {
      state.isLoadingConsultGuideToEdit = true;
    },

    startLoadingLots(state) {
      state.isLoadingLots = true;
    },

    startLoadingLotToEdit(state) {
      state.isLoadingLotToEdit = true;
    },

    startLoadingSearchGuides(state) {
      state.isLoadingSearchGuides = true;
    },

    startLoadingOptions(state) {
      state.isLoadingOptions = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAllConsultGuidesSuccess(state, action) {
      state.consultGuidesList = action.payload;
      state.isLoadingConsultGuides = false;
    },

    getAllLotsSuccess(state, action) {
      state.lotsList = action.payload;
      state.isLoadingLots = false;
    },

    getConsultGuideToEditSuccess(state, action) {
      state.consultGuideToEdit = action.payload;
      state.isLoadingConsultGuideToEdit = false;
    },

    getLotToEditSuccess(state, action) {
      state.lotToEdit = action.payload;
      state.isLoadingLotToEdit = false;
    },

    getSearchGuidesSuccess(state, action) {
      state.searchGuides = action.payload;
      state.isLoadingSearchGuides = false;
    },

    getFormOptionsSuccess(state, action) {
      state.patientOptions = action.payload.patientOptions;
      state.healthOptions = action.payload.healthOptions;
      state.cbosOptions = action.payload.cbosOptions;
      state.tuss22Options = action.payload.tuss22Options;
      state.tuss36Options = action.payload.tuss36Options;
      state.tuss52Options = action.payload.tuss52Options;
      state.isLoadingOptions = false;
    },

    getProcedureCodesSuccess(state, action) {
      state.procedureCodesOptions = action.payload;
      state.isLoadingOptions = false;
    },

    resetLotToEdit(state) {
      state.lotToEdit = null;
      state.searchGuides = [];
      state.isLoadingLotToEdit = false;
    },

    resetConsultGuideToEdit(state) {
      state.consultGuideToEdit = null;
      state.isLoadingConsultGuideToEdit = false;
    },
  },
});

export default TissSlice;

export function getAllConsultGuides(clinicId) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingConsultGuides());
    try {
      const response = await axios.get(`/appointment-guides/${clinicId}`);
      dispatch(TissSlice.actions.getAllConsultGuidesSuccess(response.data));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getAllLots() {
  return async () => {
    dispatch(TissSlice.actions.startLoadingLots());
    try {
      const response = await axios.get(`/lots`);
      dispatch(TissSlice.actions.getAllLotsSuccess(response.data));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getConsultGuideToEdit(id) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingConsultGuideToEdit());
    try {
      const result = await axios.get(`/appointment-guides/appointment/${id}`);
      dispatch(TissSlice.actions.getConsultGuideToEditSuccess(result.data));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getLotToEdit(id) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingLotToEdit());
    try {
      const aux = _lotsListComplete.find(c => c.id === id);
      await new Promise(resolve => setTimeout(resolve, 500));
      dispatch(TissSlice.actions.getLotToEditSuccess(aux));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getSearchGuides(initialDate, endDate) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingSearchGuides());
    try {
      const response = await axios.get(`/appointment-guides`);
      dispatch(TissSlice.actions.getSearchGuidesSuccess(response.data));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function resetConsultGuideToEdit() {
  return async () => {
    dispatch(TissSlice.actions.startLoadingConsultGuideToEdit());
    try {
      await new Promise(resolve => setTimeout(resolve, 500));
      dispatch(TissSlice.actions.resetConsultGuideToEdit());
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function resetLotToEdit() {
  return async () => {
    dispatch(TissSlice.actions.startLoadingLotToEdit());
    try {
      await new Promise(resolve => setTimeout(resolve, 500));
      dispatch(TissSlice.actions.resetLotToEdit());
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getTuss22Options(filter) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingOptions());
    try {
      const auxTuss22 = [{ value: '', label: '' }];
      const responseTuss22 = await axios.get(`/tuss`, {
        params: { tuss: '22' },
      });
      responseTuss22.data.forEach(p => {
        auxTuss22.push({ label: p.term, value: p._id });
      });

      dispatch(
        TissSlice.actions.getFormOptionsSuccess({
          tuss22Options: auxTuss22,
        })
      );
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getFormOptions(clinicId) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingOptions());
    try {
      const auxPatients = [{ value: '', label: '' }];
      const auxHealths = [{ value: '', label: '' }];
      const auxCbos = [{ label: '', id: '' }];
      const auxTuss22 = [{ value: '', label: '' }];
      const auxTuss36 = [{ value: '', label: '' }];
      const auxTuss52 = [{ value: '', label: '' }];
      const responsePatients = await axios.get(`/patient/clinic/${clinicId}`);
      const responseHealthInsurances = await axios.get(
        `/clinic/health-insurance/clinic/${clinicId}`
      );
      const responseTuss22 = await axios.get(`/tuss?tuss=22`);
      const responseTuss36 = await axios.get(`/tuss?tuss=36`);
      const responseTuss52 = await axios.get(`/tuss?tuss=52`);
      const responseCbos = await axios.get(`/tuss/cbos`);

      responsePatients.data.forEach(p => {
        auxPatients.push({ label: p.name, value: p.id });
      });
      responseHealthInsurances.data.forEach(p => {
        auxHealths.push({ label: p.name, value: p.id });
      });
      responseTuss22.data.forEach(p => {
        auxTuss22.push({ label: p.term, value: p._id });
      });
      responseTuss36.data.forEach(p => {
        auxTuss36.push({ label: p.term, value: p._id });
      });
      responseTuss52.data.forEach(p => {
        auxTuss52.push({ label: p.term, value: p._id });
      });
      responseCbos.data.forEach(p => {
        auxCbos.push({ label: p.title, id: p.id });
      });

      dispatch(
        TissSlice.actions.getFormOptionsSuccess({
          tuss22Options: auxTuss22,
          tuss36Options: auxTuss36,
          tuss52Options: auxTuss52,
          patientOptions: auxPatients,
          healthOptions: auxHealths,
          cbosOptions: auxCbos,
        })
      );
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function getProcedureCodes(tableCodeId) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingOptions());
    try {
      const aux =
        tableCodeId === '18'
          ? [
              { value: '', label: '' },
              { value: 'LABEL 1', label: 'LABEL 1' },
            ]
          : [{ value: '64ee3d1d8b6a2b08ec4afb6d', label: 'LABEL 2' }];

      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch(TissSlice.actions.getProcedureCodesSuccess(aux));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function deleteGuides(ids) {
  return async dispatch => {
    try {
      dispatch(TissSlice.actions.startLoading());
      const response = await axios.delete('/appointment-guides', {
        data: { ids },
      });
      dispatch(TissSlice.actions.stopLoading());
      return response.data;
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function deleteLots(ids) {
  return async () => {
    dispatch(TissSlice.actions.startLoadingLots());
    try {
      const aux = _lotsList.filter(lot => !ids.includes(lot.id));
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch(TissSlice.actions.getAllLotsSuccess(aux));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function createConsultGuide(data) {
  return async () => {
    try {
      dispatch(TissSlice.actions.startLoading());
      const response = await axios.post('appointment-guides/', data);
      dispatch(TissSlice.actions.scheduleSuccess(response.data));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function updateConsultGuide(data) {
  return async () => {
    try {
      dispatch(TissSlice.actions.startLoading());
      await axios.put(`appointment-guides/${data.id}`, data);
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function createLot(data) {
  return async () => {
    try {
      const response = await axios.post('/lots', data);
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}

export function updateLot(data) {
  return async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 500));
    } catch (error) {
      dispatch(TissSlice.actions.hasError(error));
    }
  };
}
